<template>
  <app-page-layout :title="$t('hiring.assessment.interview_score_card')" @close="leavePage">
    <template #content>
      <template v-if="loading">
        <v-layout fill-height align-center justify-center>
          <v-progress-circular indeterminate size="50" />
        </v-layout>
      </template>
      <template v-else>
        <v-layout row wrap align-center class="mb-4">
          <v-flex xs12>
            <v-list-item>
              <v-list-item-avatar
                size="80"
                class="mr-3"
                :color="result.applicantAssessment.applicant.photoLink ? 'white' : 'primary'"
              >
                <v-img
                  v-if="result.applicantAssessment.applicant.photoLink"
                  :src="result.applicantAssessment.applicant.photoLink"
                  :lazy-src="result.applicantAssessment.applicant.photoLink"
                >
                  <template v-slot:placeholder>
                    <v-layout fill-height align-center justify-center ma-0>
                      <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-layout>
                  </template>
                </v-img>
                <span
                  v-else
                  style="width: 120px; height: 120px; display: flex; align-items: center; justify-content: center; font-size: 50px"
                  class="white--text font-weight-bold"
                >
                  {{ result.applicantAssessment.applicant.name.substring(0, 1).toUpperCase()
                  }}{{ result.applicantAssessment.applicant.surName.substring(0, 1).toUpperCase() }}
                </span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="title"
                  >{{ result.applicantAssessment.applicant.name }}
                  {{ result.applicantAssessment.applicant.surName }}</v-list-item-title
                >
                <v-list-item-subtitle class="caption">{{
                  result.applicantAssessment.applicant.phone
                }}</v-list-item-subtitle>
                <v-list-item-subtitle class="caption">{{
                  result.applicantAssessment.applicant.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12 class="mt-3 mb-3">
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 text-center>
            <h3 class="title primary--text">
              <span class="display-1 font-weight-bold">{{ result.evaluatorRating.evaluatorRating.toFixed(2) }}</span>
              /
              {{ getTotalSkillScore }}
            </h3>
            <v-rating
              :color="getRatingColor"
              :value="getRatingCalculate"
              readonly
              x-large
              half-increments
              background-color="grey lighten-1"
            />
          </v-flex>
        </v-layout>

        <v-layout
          class="mt-2"
          row
          wrap
          align-center
          justify-space-between
          v-for="item in result.skillAssessmentList"
          :key="item.skill.skillId"
        >
          <v-flex xs7>
            <p class="caption">
              {{ item.skill.skillName }}
            </p>
          </v-flex>
          <v-flex shrink>
            <v-rating
              dense
              readonly
              :value="getRating(item.skillAssessment)"
              :color="getColor(item.numericSkillAssessment)"
              background-color="grey lighten-1"
            />
          </v-flex>
        </v-layout>
      </template>
    </template>
  </app-page-layout>
</template>

<script>
  import { EVALUATOR_RESULT } from "../query";

  export default {
    name: "HiringResult",
    props: {
      id: String
    },
    data: () => ({
      loading: false,
      result: null
    }),
    watch: {
      id: {
        handler() {
          this.fetchSelectedItemById();
        },
        immediate: true
      }
    },
    computed: {
      getTotalSkillScore() {
        return "5.00";
      },
      getRatingCalculate() {
        let total = (this.result.evaluatorRating.evaluatorRating * 100) / this.result.evaluatorWeight;
        return isNaN(total) ? 1 : Math.round(total);
      },
      getRatingColor() {
        let total = (this.result.evaluatorRating.evaluatorRating * 100) / this.result.evaluatorWeight;
        let rating = isNaN(total) ? 1 : Math.round(total);
        let color = null;
        if (rating === 5 && rating > 4) {
          color = "green darken-2";
        } else if (rating <= 4 && rating >= 3) {
          color = "green lighten-1";
        } else if (rating < 3 && rating >= 2) {
          color = "orange lighten-1";
        } else if (rating < 2 && rating >= 1) {
          color = "red lighten-2";
        } else {
          color = "grey";
        }
        return color;
      }
    },
    methods: {
      leavePage() {
        this.$router.push({ name: "hiring" });
      },
      getColor(rating) {
        let color = null;
        if (rating === 5 && rating > 4) {
          color = "green darken-2";
        } else if (rating <= 4 && rating >= 3) {
          color = "green lighten-1";
        } else if (rating < 3 && rating >= 2) {
          color = "orange lighten-1";
        } else if (rating < 2 && rating >= 1) {
          color = "red lighten-2";
        } else {
          color = "grey";
        }
        return color;
      },
      getAssessmentText(rating) {
        let text = null;
        if (rating === 5 && rating > 4) {
          text = this.$t("assessment.very_good");
        } else if (rating <= 4 && rating >= 3) {
          text = this.$t("assessment.good");
        } else if (rating < 3 && rating >= 2) {
          text = this.$t("assessment.normal");
        } else if (rating < 2 && rating >= 1) {
          text = this.$t("assessment.poor");
        } else {
          text = this.$t("assessment.na");
        }

        return text;
      },

      getRating(assessment) {
        let rating = 1;
        switch (assessment) {
          case "VERY_POOR":
            rating = 1;
            break;
          case "POOR":
            rating = 2;
            break;
          case "NORMAL":
            rating = 3;
            break;
          case "GOOD":
            rating = 4;
            break;
          case "VERY_GOOD":
            rating = 5;
            break;
          default:
            rating = 0;
        }
        return rating;
      },

      async fetchSelectedItemById() {
        this.loading = true;
        await this.$apollo
          .query({
            client: "hiring",
            query: EVALUATOR_RESULT,
            variables: {
              id: this.$helpers.decodeId(this.id),
              empId: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.result = data.findByAssessmentAndEmployeeId;
            }
          })
          .finally(() => (this.loading = false));
      }
    }
  };
</script>

<style scoped></style>
